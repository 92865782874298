import { useRef } from 'react';
import { clsx } from 'clsx';
import { useHorizontalDragScroll } from '@innhold/lib/hooks/useHorizontalDragScroll';
import { STRIPE_TICKERS_SELECTION } from './constants';
import { useLiveTickers } from './hooks/useLiveTickers';
import { StripeTicker } from './StripeTicker';
import { trackStocksOverviewClick } from './tracking.ts';
import type { Ticker } from './types';
import css from './StocksStripe.module.css';

export const StocksStripe = ({
    tickers: initialTickers,
}: {
    tickers: { [key: string]: Ticker };
}) => {
    const stripeScrollerRef = useRef<HTMLUListElement>(null);
    const { eventHandlers: dragScrollEventHandlers, isDragging } =
        useHorizontalDragScroll(stripeScrollerRef);

    const tickers = useLiveTickers(initialTickers);
    const borsUrl = 'https://e24.no/bors';

    return tickers ? (
        <div className="stocks-stripe">
            <div className={css.stocksStripeContainer}>
                <div className={css.stocksStripeViewport}>
                    <ul
                        ref={stripeScrollerRef}
                        className={clsx(css.stocksStripeScroller, {
                            [css.dragging]: isDragging,
                        })}
                        {...dragScrollEventHandlers}
                    >
                        {STRIPE_TICKERS_SELECTION.map((symbol) => (
                            <li key={symbol}>
                                <StripeTicker ticker={tickers[symbol]} />
                            </li>
                        ))}
                    </ul>
                    <a
                        className={css.stocksOverviewLink}
                        href={borsUrl}
                        onClick={() => trackStocksOverviewClick(borsUrl)}
                    >
                        Børs →
                    </a>
                </div>
            </div>
        </div>
    ) : null;
};
