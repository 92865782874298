export function timeoutPromiseWithAbort(ms: number, { signal }: { signal: AbortSignal }) {
    return new Promise((resolve) => {
        const abortTimeoutPromise = () => clearTimeout(timeout);
        const timeout = setTimeout(() => {
            signal.removeEventListener('abort', abortTimeoutPromise);
            resolve(null);
        }, ms);

        signal.addEventListener('abort', abortTimeoutPromise);
    });
}
