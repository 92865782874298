export function formatNumber(rawValue: number): string {
    if (typeof rawValue !== 'number' || Number.isNaN(rawValue)) {
        return '-';
    }

    const decimals = rawValue > 999.9 ? 0 : rawValue > 99.99 ? 1 : 2;
    const value = new Intl.NumberFormat('nb-NO', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(rawValue);

    return value.replace(',', '.');
}
