// INFO: Based on list of highlighted cryptocurrencies from bors
export enum CryptoCurrencySymbol {
    BTC = 'BTC',
    ETH = 'ETH',
    BNB = 'BNB',
    XRP = 'XRP',
    SOL = 'SOL',
}

type BaseCryptoCurrency = {
    displayName: string;
    value: number;
};
export type CryptoCurrencyResponseItem = BaseCryptoCurrency & {
    shortName: CryptoCurrencySymbol;
    changePctIntraDay: number;
};
export type CryptoCurrency = BaseCryptoCurrency & {
    key: string;
    symbol: CryptoCurrencySymbol;
    changePct: number;
    topic: null;
    url: string;
};

type BaseStocksTicker = {
    key: string;
    symbol: string;
    displayName: string;
    topic: string;
    value: number;
};
export type StockTickerResponseItem = BaseStocksTicker & {
    changePctIntraDay: number;
};
export type StockTicker = BaseStocksTicker & {
    changePct: number;
    url: string;
};

export type Ticker = CryptoCurrency | StockTicker;
