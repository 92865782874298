import { clsx } from 'clsx';
import { formatNumber } from '@innhold/lib/formatters/formatNumber';
import { STRIPE_TICKERS_SETTINGS } from './constants';
import { trackTickerClick } from './tracking';
import type { StockTicker } from './types';
import css from './StocksStripe.module.css';

const formatTickerName = (symbol: string, rawDisplayName: string) => {
    const { name } = STRIPE_TICKERS_SETTINGS[symbol] ?? {};
    return name || rawDisplayName;
};

const formatTickerValue = (symbol: string, rawValue: number) => {
    const { prefix = '', postfix = '' } = STRIPE_TICKERS_SETTINGS[symbol] ?? {};
    const value = formatNumber(rawValue);
    return `${prefix}${value}${postfix}`;
};

const formatTickerLabel = (name: string, change: string, rawChange: number) => {
    if (rawChange > 0) return `${name} opp ${change}`;
    if (rawChange < 0) return `${name} ned ${change}`;
    return `${name} uendret`;
};

export const StripeTicker = ({ ticker }: { ticker: StockTicker }) => {
    if (!ticker) {
        return null;
    }

    const {
        symbol,
        displayName: rawDisplayName,
        value: rawValue,
        changePct: rawChange,
        url,
    } = ticker;

    const name = formatTickerName(symbol, rawDisplayName);
    const value = formatTickerValue(symbol, rawValue);
    const change = `${formatNumber(Math.abs(rawChange))}%`;
    const label = formatTickerLabel(name, change, rawChange);

    return (
        <a
            className={clsx(css.stripeTicker, {
                [css.positive]: rawChange > 0,
                [css.neutral]: rawChange === 0,
                [css.negative]: rawChange < 0,
            })}
            aria-label={label}
            href={url}
            draggable={false}
            onClick={() => trackTickerClick(symbol)}
        >
            <span className={css.name}>{name}</span>
            <span className={css.value}>{value}</span>
            <span className={css.change}>{change}</span>
        </a>
    );
};
