import { useEffect, useState } from 'react';
import { eventhub } from '@innhold/core/eventhub/client';
import { log } from '@innhold/core/logger';
import { fetchCryptoCurrencies } from '../api/fetchCryptoCurrencies';
import type { Ticker } from '../types';
import { mapLiveStockTicker } from '../utils/mapTickers';

export const useLiveTickers = (initialTickers: { [key: string]: Ticker }) => {
    const [tickers, setTickers] = useState(initialTickers);

    useEffect(() => {
        const topics = Object.values(initialTickers)
            .map(({ topic }) => topic)
            .filter(Boolean);

        eventhub.subscribeTo(topics, (response) => {
            if (!response?.message) {
                return;
            }

            try {
                const ticker = JSON.parse(response.message);
                const stockTicker = mapLiveStockTicker(ticker);

                setTickers((prevTickers) => {
                    const prevStockTicker = Object.values(prevTickers).find(
                        ({ key }) => key === stockTicker.key
                    );

                    return prevStockTicker
                        ? {
                              ...prevTickers,
                              [prevStockTicker.symbol]: {
                                  ...prevStockTicker,
                                  ...stockTicker,
                              },
                          }
                        : prevTickers;
                });
            } catch (error) {
                log.error('Error! Failed to update stock stripe ticker!');
            }
        });

        return () => {
            eventhub.unsubscribeFrom(topics);
        };
    }, [initialTickers]);

    useEffect(() => {
        const fetchLiveCryptoCurrencies = async () => {
            const currencies = await fetchCryptoCurrencies();
            setTickers((prevTickers) => ({ ...prevTickers, ...currencies }));
        };
        const fetchInterval = setInterval(
            () => fetchLiveCryptoCurrencies(),
            30 * 1000 /* 30 seconds */
        );

        return () => {
            clearInterval(fetchInterval);
        };
    }, []);

    return tickers;
};
