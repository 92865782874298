import Eventhub from 'eventhub-jsclient';
import { log } from '@innhold/core/logger';
import { timeoutPromiseWithAbort } from '@innhold/lib/timers/withAbort';

type EventhubConnection = { ok: boolean };
type EventhubSubscriptionCallback = (response: { id: string; message: string }) => void;

const EVENTHUB_URL = 'wss://eventhub.e24.no';
const EVENTHUB_RETRY_LIMIT = 10;

class EventhubClient {
    private client: Eventhub = null;
    private connectionPromise: Promise<EventhubConnection> = null;
    private retryController: AbortController = null;
    private subscriptionCount = 0;
    private subscriptionsToRestore = new Map();

    constructor() {
        this.init();
    }

    private async init() {
        this.connectionPromise = this.connect();

        window.addEventListener('pagehide', () => {
            this.disconnect();
        });

        window.addEventListener('pageshow', () => {
            this.reconnect();
        });
    }

    private async connect(): Promise<EventhubConnection | null> {
        if (this.connectionPromise) {
            return this.connectionPromise;
        }

        const client = this.getEventhub();
        if (!client) {
            log.error('Eventhub client missing. Aborting.');
            return null;
        }

        this.client = client;

        return this.connectWithRetries();
    }

    private async connectWithRetries({
        retries = 0,
    } = {}): Promise<EventhubConnection | null> {
        try {
            await this.client.connect();
        } catch (error) {
            log.error('Error! Failed to connect to Eventhub!', error);

            if (!this.retryController) {
                this.retryController = new AbortController();
            }

            if (retries > EVENTHUB_RETRY_LIMIT) {
                log.error('Eventhub retry limit reached. Aborting.');
                return null;
            }

            // Retry with custom backoff strategy
            await timeoutPromiseWithAbort(1500 + 1000 * retries, {
                signal: this.retryController.signal,
            });

            return this.connectWithRetries({ retries: retries + 1 });
        }

        return { ok: true };
    }

    private async reconnect() {
        if (this.connectionPromise) {
            return;
        }

        this.connectionPromise = this.connect();
        await this.restoreSubscriptions();
    }

    private async disconnect() {
        if (!this.client) {
            return;
        }

        if (this.retryController) {
            this.retryController.abort();
            this.retryController = null;
        }

        if (this.subscriptionCount) {
            this.client.unsubscribeAll();
            this.subscriptionCount = 0;
        }

        this.client.disconnect();
        this.client = null;
        this.connectionPromise = null;
    }

    async subscribeTo(topics: string | string[], callback: EventhubSubscriptionCallback) {
        const connection = await this.waitForEventhubConnection();
        if (!connection) {
            return [];
        }

        const topicList = Array.isArray(topics) ? topics : [topics];
        return Promise.all(topicList.map((topic) => this.subscribe(topic, callback)));
    }

    async unsubscribeFrom(topics: string | string[]) {
        const connection = await this.waitForEventhubConnection();
        if (!connection) {
            return [];
        }

        const topicList = Array.isArray(topics) ? topics : [topics];
        return Promise.all(topicList.map((topic) => this.unsubscribe(topic)));
    }

    private async restoreSubscriptions() {
        const connection = await this.waitForEventhubConnection();
        if (!connection) {
            return [];
        }

        return Promise.all(
            Array.from(this.subscriptionsToRestore.entries()).map(
                async ([topic, handler]) => {
                    try {
                        const { status } = await this.client.subscribe(topic, handler);
                        this.subscriptionCount += 1;
                        return { action: 'restore', status, topic };
                    } catch (error) {
                        log.error(
                            `Error! Failed to restore subscription for ${topic}!`,
                            error
                        );
                        return { action: 'restore', status: 'fail', topic };
                    }
                }
            )
        );
    }

    private async subscribe(topic: string, callback: EventhubSubscriptionCallback) {
        try {
            const result = await this.client.subscribe(topic, callback);

            this.subscriptionCount += 1;
            this.subscriptionsToRestore.set(topic, callback);

            return result;
        } catch (error) {
            log.error(`Error! Failed to subscribe to ${topic}!`, error);
            return { action: 'subscribe', status: 'fail', topic };
        }
    }

    private async unsubscribe(topic: string) {
        try {
            this.client.unsubscribe(topic);

            this.subscriptionCount -= 1;
            this.subscriptionsToRestore.delete(topic);

            return { action: 'unsubscribe', status: 'success', topic };
        } catch (error) {
            log.error(`Error! Failed to unsubscribe from ${topic}!`, error);
            return { action: 'unsubscribe', status: 'fail', topic };
        }
    }

    private getEventhub() {
        const token = this.getEventhubToken();
        if (!token) {
            log.error('Error! Failed to locate Eventhub token!');
            return null;
        }

        return new Eventhub(EVENTHUB_URL, token);
    }

    private getEventhubToken() {
        try {
            const { t: token } = window.__EVENTHUB__;
            return token;
        } catch (error) {
            log.error('Error! Failed to locate Eventhub config!');
            return null;
        }
    }

    private async waitForEventhubConnection() {
        if (!this.connectionPromise) {
            log.error('Error! No Eventhub connection available!');
            return null;
        }

        return this.connectionPromise;
    }
}

const isServer = import.meta.env.SSR;
export const eventhub = isServer ? null : new EventhubClient();
