import { CryptoCurrencySymbol } from './types';

const BITCOIN = 'BTC';
const BRENT_SPOT = 'C:PBROUSDBR\\SP.IDCENE';
const NASDAQ_COMPOSITE = 'I:COMP.NAS';
const DOWN_JONES_INDUSTRIAL = 'I:DJI.DJ';
const HANG_SENG = 'I:HSI.HSI';
const SP_500 = 'I:SP500.SP';
const OSLO_EXCHANGE = 'OSEBX.OSE';
const STOCKHOLM_EXCHANGE = 'OMXS30.SSE';
const EUR_NOK = 'X:SEURNOK.IDCFX';
const USD_NOK = 'X:SUSDNOK.IDCFX';

export const STRIPE_TICKERS_SELECTION = [
    OSLO_EXCHANGE,
    BRENT_SPOT,
    SP_500,
    USD_NOK,
    BITCOIN,
    EUR_NOK,
    HANG_SENG,
    STOCKHOLM_EXCHANGE,
    NASDAQ_COMPOSITE,
    DOWN_JONES_INDUSTRIAL,
] as const;

export const STRIPE_TICKERS_SELECTION_BY_TYPE = STRIPE_TICKERS_SELECTION.reduce(
    (selectionByType, symbol) => {
        if (symbol in CryptoCurrencySymbol) {
            selectionByType.CRYPTO.push(symbol);
        } else {
            selectionByType.STOCK.push(symbol);
        }

        return selectionByType;
    },
    { CRYPTO: [], STOCK: [] }
);

export const STRIPE_TICKERS_SETTINGS: {
    [K in (typeof STRIPE_TICKERS_SELECTION)[number]]: {
        name: string;
        prefix?: string;
        postfix?: string;
    };
} = {
    [OSLO_EXCHANGE]: { name: 'Oslo' },
    [BRENT_SPOT]: { name: 'Olje', prefix: '$ ' },
    [SP_500]: { name: 'S&P 500' },
    [USD_NOK]: { name: 'USD', postfix: ' kr' },
    [BITCOIN]: { name: 'BTC', prefix: '$ ' },
    [EUR_NOK]: { name: 'EURO', postfix: ' kr' },
    [HANG_SENG]: { name: 'Hong Kong' },
    [STOCKHOLM_EXCHANGE]: { name: 'Stockholm' },
    [NASDAQ_COMPOSITE]: { name: 'Nasdaq' },
    [DOWN_JONES_INDUSTRIAL]: { name: 'Dow Jones' },
};
