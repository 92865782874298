import { log } from '@innhold/core/logger';
import { STRIPE_TICKERS_SELECTION_BY_TYPE } from '../constants';
import type { CryptoCurrency, CryptoCurrencyResponseItem } from '../types';
import { mapCryptoCurrency } from '../utils/mapTickers';

type ResponseOf<T> = { [key: string]: T };

export const fetchCryptoCurrencies = async (
    symbols: string[] = STRIPE_TICKERS_SELECTION_BY_TYPE.CRYPTO
): Promise<ResponseOf<CryptoCurrency>> => {
    try {
        const response = await fetch(
            'https://api.e24.no/bors/v2/crypto-currencies/highlighted'
        );

        if (!response.ok) {
            throw new Error(
                `Fetching crypto currencies failed with status ${response.status}`
            );
        }

        const currencies: CryptoCurrencyResponseItem[] = await response.json();

        return currencies
            .filter(({ shortName }) => symbols.includes(shortName))
            .reduce((result, currency) => {
                result[currency.shortName] = mapCryptoCurrency(currency);
                return result;
            }, {} as ResponseOf<CryptoCurrency>);
    } catch (error) {
        log.error('Error! Failed to fetch crypto currencies!', error);
        return {};
    }
};
