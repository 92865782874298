import type {
    CryptoCurrency,
    CryptoCurrencyResponseItem,
    StockTicker,
    StockTickerResponseItem,
} from '../types';

export const mapCryptoCurrency = ({
    shortName,
    displayName,
    value,
    changePctIntraDay,
}: CryptoCurrencyResponseItem): CryptoCurrency => ({
    key: shortName,
    symbol: shortName,
    displayName,
    value,
    changePct: changePctIntraDay,
    topic: null,
    url: 'https://e24.no/bors/kryptovaluta',
});

export const mapLiveStockTicker = (
    ticker: Partial<StockTickerResponseItem>
): Partial<StockTicker> => {
    const stockTicker = { key: ticker.key } as Partial<StockTicker>;

    if (!('value' in ticker) || !('changePctIntraDay' in ticker)) {
        return stockTicker;
    }

    return {
        ...stockTicker,
        value: ticker.value,
        changePct: ticker.changePctIntraDay,
    };
};

export const mapStockTicker = ({
    key,
    symbol,
    displayName,
    value,
    changePctIntraDay,
    topic,
}: StockTickerResponseItem): StockTicker => ({
    key,
    symbol,
    displayName,
    value,
    changePct: changePctIntraDay,
    topic,
    url: `https://e24.no/bors/instrument/${encodeURIComponent(symbol)}`,
});
